// import { useLocalStorageState } from "ahooks"
import { graphql, useStaticQuery } from "gatsby"
import gsap from "gsap"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import type { ComponentProps } from "react"
import { useEffect, useRef } from "react"
import Markdown from "react-markdown"
import styled, { css } from "styled-components"
import textStyles from "styles/text"

function LinkRenderer(props: ComponentProps<"a">) {
	return (
		<a href={props.href} target="_blank" rel="noreferrer">
			{props.children}
		</a>
	)
}

export function Banner() {
	const banners: Queries.BannerQuery = useStaticQuery(graphql`
		query Banner {
			allContentfulAnnouncementBannerMessage(sort: { messagePriority: DESC }) {
				edges {
					node {
						id
						bannerMessage {
							bannerMessage
						}
						showMessageAt
						hideMessageAt
						messagePriority
					}
				}
			}
		}
	`)

	const bannerToShow = banners.allContentfulAnnouncementBannerMessage.edges
		.map((e) => e.node)
		.find(
			(b) =>
				// start date either undefined or is in the past
				(b.showMessageAt === null || new Date(b.showMessageAt) < new Date()) &&
				// end date is in the future
				b.hideMessageAt &&
				new Date(b.hideMessageAt) > new Date(),
		)

	// const [hiddenMessageId, setHiddenMessageId] =
	//   useLocalStorageState("hiddenMessageId")
	const wrapper = useRef<HTMLDivElement>(null)

	useEffect(() => {
		gsap.to(wrapper.current, {
			height: "auto",
			duration: 0.5,
			delay: 5,
			ease: "power3.inOut",
		})
	}, [])

	// if (hiddenMessageId === bannerToShow?.id) return null
	if (!bannerToShow?.bannerMessage?.bannerMessage) return null

	return (
		<Wrapper ref={wrapper}>
			<Text components={{ a: LinkRenderer }}>
				{bannerToShow.bannerMessage.bannerMessage}
			</Text>
			<CloseButton
				type="button"
				onClick={() => {
					gsap.to(wrapper.current, {
						height: 0,
						duration: 0.5,
						ease: "power3.inOut",
						// onComplete: () => setHiddenMessageId(bannerToShow.id),
					})
				}}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
				>
					<title>Dismiss Message</title>
					<path d="M1 1L11 11" stroke="white" />
					<path d="M11 1L0.999999 11" stroke="white" />
				</svg>
			</CloseButton>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	${fresponsive(css`
		height: 0;
		overflow: clip;
		padding: 0 53px;
		background: #011aff;
		${textStyles.subHeading4};
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
	`)}

	${ftablet(css`
		padding: 0 50px;
	`)}

  ${fmobile(css`
		padding: 0 26px 0 18px;
		justify-content: space-between;
		gap: 96px;
	`)}
`

const Text = styled(Markdown)`
	${fresponsive(css`
		padding-left: 10px;
		margin: 11px auto;
	`)}

	${ftablet(css`
		padding-left: 15px;
		margin: 17px auto;
	`)}

  ${fmobile(css`
		padding-left: 0;
		margin: 16px 0;
	`)}

  * {
		display: inline;
	}

	a {
		text-decoration: underline;
	}
`

const CloseButton = styled(UniversalLink)`
	margin: -20px;
	padding: 20px;

	${fresponsive(css`
		svg {
			width: 10px;
			height: 10px;
		}
	`)}

	${ftablet(css`
		svg {
			width: 15px;
			height: 15px;
		}
	`)}

  ${fmobile(css`
		svg {
			width: 15px;
			height: 15px;
		}
	`)}
`
