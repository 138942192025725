import { ReactComponent as LinesSVG } from "images/global/footerLines.svg"
import { ReactComponent as LinesMSVG } from "images/global/footerLinesM.svg"
import { ReactComponent as LinesTSVG } from "images/global/footerLinesT.svg"
import { ReactComponent as FlowFiLogoSVG } from "images/global/logo.svg"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import getMedia from "library/getMedia"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"

import AnimatedPath from "./AnimatedPaths"

export interface Link {
	link: string
	to: string
}

export interface LinkObj {
	title: string
	links: Link[]
	order: number
}

export default function Footer() {
	const currentYear = new Date().getFullYear()

	const LINKS: LinkObj[] = [
		{
			title: "Products",
			links: [
				{
					link: "Accounting",
					to: links.accounting,
				},
				{ link: "CFO", to: links.cfo },
				{ link: "Tax", to: links.tax },
			],
			order: 1,
		},
		{
			title: "Company",
			links: [
				{ link: "Become an Expert", to: links.expert },
				{ link: "Company", to: links.company },
				{ link: "Careers", to: links.careers },
				// { link: "Blog", to: links.blog },
				{ link: "FAQs", to: links.faqs },
			],
			order: getMedia(2, 2, 3, 3),
		},
		{
			title: "Contact Us",
			links: [
				{ link: "Email", to: links.mailto },
				{ link: "Linkedin", to: links.linkedin },
				{ link: "X", to: links.twitter },
			],
			order: getMedia(3, 3, 2, 2),
		},
		{
			title: "Legal",
			links: [
				{ link: "Terms", to: links.terms },
				{ link: "Privacy Policy", to: links.privacy },
			],
			order: 4,
		},
	]

	const allColumns = LINKS.sort((a, b) => a.order - b.order).map((linkObj) => {
		return (
			<Column key={linkObj.title}>
				<ColumnTitle>{linkObj.title}</ColumnTitle>
				<ColumnLinks>
					{linkObj.links.map(({ link, to }) => {
						return (
							<LinkWrapper key={link}>
								<FooterLink to={to}>{link}</FooterLink>
							</LinkWrapper>
						)
					})}
				</ColumnLinks>
			</Column>
		)
	})

	return (
		<Wrapper>
			<Inner>
				<Top>
					<UniversalLink to="/" aria-label="home">
						<FooterLogo />
					</UniversalLink>
					<Links>{allColumns}</Links>
				</Top>
				<AnimatedPath
					className="footer-animated-path"
					animationClassName="footer-path"
				>
					<Lines />
					<LinesT />
				</AnimatedPath>

				<StyledAnimatedPathM
					className="footer-animated-path"
					animationClassName="footer-path"
					sizeRange={[35, 50]}
					durationRange={[0.5, 1]}
				>
					<LinesM />
				</StyledAnimatedPathM>
				<Bottom>
					<LegalTitle>{currentYear} © Flow Finance Inc.</LegalTitle>
				</Bottom>
			</Inner>
		</Wrapper>
	)
}

const Column = styled.div`
	display: flex;
	flex-direction: column;

	${fresponsive(css`
		gap: 20px;
	`)}

	${fmobile(css`
		width: fit-content;
	`)}
`

const ColumnTitle = styled.h4`
	${textStyles.subHeading4Bold};
	color: ${colors.dark400};
`

const ColumnLinks = styled.div`
	display: flex;
	flex-direction: column;

	${fresponsive(css`
		gap: 22px;
	`)}
`

const LinkWrapper = styled.div`
	${textStyles.button2Medium};
	${fresponsive(css`
		font-size: 16px;
	`)}
`

const FooterLink = styled(UniversalLink)`
	${textStyles.button2Medium};
	transition: 0.4s;

	&:hover {
		color: ${colors.mainBlue};
	}
`

const Wrapper = styled.footer`
	width: 100%;
	overflow: hidden;
	position: relative;

	${fresponsive(css`
		padding: 40px;
	`)}

	${ftablet(css`
		padding: 20px;
	`)}

  ${fmobile(css`
		padding: 10px;
	`)}
`

const Inner = styled.div`
	background-color: ${colors.mainDarkBlack};
	color: white;
	height: auto;
	display: flex;
	flex-direction: column;
	width: 100%;

	${fresponsive(css`
		padding: 40px 0;
		border-radius: 28px;
	`)}

	${fmobile(css`
		padding: 28px 0 25px 20px;
	`)}
`

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	position: relative;
	z-index: 2;

	${fresponsive(css`
		margin: 0 0 0 40px;
	`)}

	${fmobile(css`
		flex-direction: column;
		gap: 161px;
		margin: 0;
	`)}
`

const FooterLogo = styled(FlowFiLogoSVG)`
	path {
		fill: white;
	}
	${fresponsive(css`
		width: 97px;
		height: 26px;
	`)}

	${fmobile(css`
		width: 122px;
		height: 33px;
	`)}
`

const Links = styled.div`
	display: flex;
	${fresponsive(css`
		margin-right: 149px;
		gap: 100px;
	`)}

	${ftablet(css`
		width: 372px;
		height: 380px;
		margin-right: 110px;
		flex-flow: column wrap;
		gap: 40px 130px;
	`)}

  ${fmobile(css`
		width: 355px;
		height: 365px;
		margin: 0 0 87px;
		flex-flow: column wrap;
		justify-content: space-between;
		gap: 50px 55px;
	`)}
`

const Lines = styled(LinesSVG)`
	position: relative;
	display: block;

	${fresponsive(css`
		width: 100%;
	`)}

	${ftablet(css`
		display: none;
	`)}

  ${fmobile(css`
		display: none;
	`)}
`

const LinesT = styled(LinesTSVG)`
	position: relative;
	display: block;

	${fresponsive(css`
		display: none;
	`)}

	${ftablet(css`
		display: block;
		width: 100%;
	`)}
`

const StyledAnimatedPathM = styled(AnimatedPath)`
	display: none;

	${fmobile(css`
		display: inline-block;
		position: absolute;
		z-index: 1;
		width: 355px;
		height: 626px;
		top: 50px;
		left: 10px;
	`)}
`

const LinesM = styled(LinesMSVG)`
	${fresponsive(css`
		display: none;
	`)}
	${fmobile(css`
		display: block;
		width: 100%;
		height: 100%;
	`)}
`

const Bottom = styled.div`
	display: flex;
	justify-content: space-between;
	position: relative;
	z-index: 2;

	${fresponsive(css`
		margin: -20px 0 0 40px;
		padding-right: 47px;
	`)}

	${fmobile(css`
		margin: 0;
		padding: 0;
	`)}
`

const LegalTitle = styled.h5`
	${textStyles.subHeading4Bold};
	color: ${colors.dark400};
`
