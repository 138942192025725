import { ReactComponent as TopperSVG } from "images/global/Topper.svg"
import styled from "styled-components"

export default function DropdownBackground() {
	return <Wrapper />
}

const Wrapper = styled(TopperSVG)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
`
