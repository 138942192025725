import Secondary from "components/Buttons/Secondary"
import gsap from "gsap"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import { ReactComponent as ArrowSVG } from "images/global/arrow.svg"
import { ReactComponent as LogoSVG } from "images/global/logo.svg"
import { ReactComponent as AccountingSVG } from "images/icons/accounting.svg"
import { ReactComponent as CFOSVG } from "images/icons/cfo.svg"
import { ReactComponent as TaxSVG } from "images/icons/tax.svg"
import { loader } from "library/Loader"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive } from "library/fullyResponsive"
import getMedia from "library/getMedia"
import useAnimation from "library/useAnimation"
import { getPxToVw, getVwToPx } from "library/viewportUtils"
import { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"

export default function TabletMobile() {
	const [burgerEl, setBurgerEl] = useState<HTMLDivElement | null>(null)
	const [open, setOpen] = useState(false)
	const [line1, setLine1] = useState<SVGLineElement | null>(null)
	const [line2, setLine2] = useState<SVGLineElement | null>(null)
	const [line3, setLine3] = useState<SVGLineElement | null>(null)

	const timeline = useAnimation(
		() => {
			// elements need to exist to animate them
			if (!burgerEl && timeline && !line1 && !line2 && !line3) return

			const tl = gsap.timeline({
				paused: true,
			})
			tl.fromTo(
				burgerEl,
				{
					height: () => getVwToPx(getPxToVw(62)),
					width: () =>
						getMedia(
							getVwToPx(getPxToVw(154)),
							getVwToPx(getPxToVw(154)),
							getVwToPx(getPxToVw(154)),
							getVwToPx(getPxToVw(92)),
						),
					borderRadius: () => getVwToPx(getPxToVw(15)),
				},
				{
					height: () => getVwToPx(getPxToVw(504)),
					width: () =>
						getMedia(
							getVwToPx(getPxToVw(370)),
							getVwToPx(getPxToVw(370)),
							getVwToPx(getPxToVw(370)),
							getVwToPx(getPxToVw(339)),
						),
					borderRadius: () => getVwToPx(getPxToVw(20)),
				},
				0,
			)

			tl.fromTo(
				line1,
				{
					attr: {
						x1: 0,
						x2: 40,
						y1: 1,
						y2: 1,
					},
				},
				{
					attr: {
						x1: 20,
						x2: 40,
						y1: 1,
						y2: 19,
					},
				},
				0,
			)

			tl.fromTo(
				line2,
				{
					attr: {
						x1: 0,
						x2: 40,
						y1: 10,
						y2: 10,
					},
				},
				{
					attr: {
						x1: 20,
						x2: 40,
						y1: 1,
						y2: 19,
					},
				},
				0,
			)

			tl.fromTo(
				line3,
				{
					attr: {
						x1: 0,
						x2: 40,
						y1: 19,
						y2: 19,
					},
				},
				{
					attr: {
						x1: 40,
						x2: 20,
						y1: 1,
						y2: 19,
					},
				},
				0,
			)

			return tl
		},
		[burgerEl, line1, line2, line3],
		{
			recreateOnResize: true,
		},
	)

	useEffect(() => {
		// timeline needs to exists in order to play or reverse it
		if (!timeline) return

		if (open) {
			ScrollSmoother.get()?.paused(true)
			timeline.play()
		} else {
			ScrollSmoother.get()?.paused(false)
			timeline.reverse()
		}
	}, [timeline, open])

	/**
	 * close the menu when clicking a link
	 */
	loader.useEventListener("start", () => setOpen(false))
	loader.useEventListener("scroll", () => setOpen(false))

	const handleClick = () => {
		setOpen((val) => !val)
	}

	return (
		<Wrapper>
			<LogoWrap
				className="header-perpetual-home"
				to={links.home}
				aria-label="home"
			>
				<StaticLogo />
			</LogoWrap>
			<BurgerWrapper
				open={open}
				onClick={() => {
					if (!open) setOpen(true)
				}}
			>
				<Burger ref={(ref) => setBurgerEl(ref)}>
					<IconText onClick={handleClick} aria-label="open menu">
						<Close viewBox="0 0 41 20">
							<SVGLine
								ref={(ref) => setLine1(ref)}
								x1={0}
								x2={40}
								y1={1}
								y2={1}
							/>
							<SVGLine
								ref={(ref) => setLine2(ref)}
								x1={0}
								x2={40}
								y1={10}
								y2={10}
							/>
							<SVGLine
								ref={(ref) => setLine3(ref)}
								x1={0}
								x2={40}
								y1={19}
								y2={19}
							/>
						</Close>
						<MenuClose>{open ? "Close" : "Menu"}</MenuClose>
					</IconText>
					<Menu>
						<InsideLogo to={links.home}>
							<Logo />
						</InsideLogo>
						<Line />
						<Label>Products</Label>
						<Products>
							<Product to={links.accounting}>
								<Accounting />
								<ProductText>Accounting</ProductText>
							</Product>
							<Product to={links.cfo}>
								<Cfo />
								<ProductText>CFO</ProductText>
							</Product>
							<Product to={links.tax}>
								<Tax />
								<ProductText>Tax</ProductText>
							</Product>
						</Products>
						<Links>
							<Link to={links.expert}>
								<Label>Become an Expert</Label>
								<Arrow />
							</Link>
							<Link to={links.company}>
								<Label>Company</Label>
								<Arrow />
							</Link>
						</Links>
						<Line />
						<Buttons>
							<StyledButton to={links.signIn} border>
								Sign In
							</StyledButton>
							<Secondary to={links.demo}>Book Demo</Secondary>
						</Buttons>
					</Menu>
				</Burger>
			</BurgerWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: start;

	&::before {
		position: absolute;
		inset: 0;
		z-index: -1;
		content: "";
		background: linear-gradient(
				to bottom,
				rgb(255 255 255 / 80%) -4.03%,
				rgb(255 255 255 / 0%) 95.3%
			),
			rgb(255 255 255 / 55%);
		backdrop-filter: blur(3.5px);
	}
`

const LogoWrap = styled(UniversalLink)`
	${fresponsive(css`
		margin-top: 45px;
		margin-right: -100%;
	`)}
	${fmobile(css`
		margin-top: 35px;
	`)}
`

const StaticLogo = styled(LogoSVG)`
	${fresponsive(css`
		width: 100px;
		margin: 0 30px;
	`)}

	${fmobile(css`
		width: 78.35px;
		margin: 0 18px;
	`)}
`

const BurgerWrapper = styled.header<{ open: boolean }>`
	width: ${({ open }) => (open ? "100%" : "50%")};
	transition: width 0.5s ease-in-out;
	display: flex;
	align-items: start;
	justify-content: flex-end;

	${fresponsive(css`
		padding: 30px;
		height: 122px;
	`)}

	${fmobile(css`
		padding: 18px;
		height: 98px;
	`)}
`

const Burger = styled.div`
	position: relative;
	background-color: ${colors.mainWhite};
	overflow: hidden;

	${fresponsive(css`
		width: 154px;
		height: 62px;
		border-radius: 15px;
		box-shadow: 0 6.7753px 17.7851px 0 rgb(0 0 0 / 6%);
	`)}

	${fmobile(css`
		width: 92px;
	`)}
`

const IconText = styled.button`
	position: absolute;
	display: flex;
	align-items: center;
	z-index: 9;

	${fresponsive(css`
		gap: 15px;
		top: 20px;
		right: 20px;
		height: 20px;
	`)}

	${fmobile(css`
		right: 25px;
		top: 20px;
	`)}
`

const Close = styled.svg`
	${fresponsive(css`
		height: 20px;
		width: 40px;
	`)}
`

const SVGLine = styled.line`
	stroke-width: 2px;
	stroke: ${colors.mainBlue};
`

const MenuClose = styled.span`
	${textStyles.button1Bold}
	color: ${colors.mainBlack};
	vertical-align: middle;

	${fmobile(css`
		display: none;
	`)}
`

const Menu = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;

	${fresponsive(css`
		padding: 20px;
		width: 370px;
		height: 504px;
	`)}

	${fmobile(css`
		width: 339px;
	`)}
`

const InsideLogo = styled(UniversalLink)`
	align-self: start;
`

const Logo = styled(LogoSVG)`
	height: auto;
	${fresponsive(css`
		width: 80px;
	`)}
`

const Line = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.light600};

	${fresponsive(css`
		margin: 25px 0;
	`)}
`

const Label = styled.span`
	${textStyles.button1Bold}

	${fresponsive(css`
		padding-left: 10px;
	`)}
`

const Products = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${colors.light300};

	${fresponsive(css`
		gap: 5px;
		padding: 8px 5px;
		border-radius: 10px;
		margin: 15px 0;
	`)}
`

const Product = styled(UniversalLink)`
	display: flex;
	align-items: center;
	width: 100%;

	${fresponsive(css`
		height: 42px;
		gap: 10px;
		padding: 10px;
	`)}
`

const ProductIconStyles = css`
	height: auto;

	path {
		fill: ${colors.dark400};
	}

	${fresponsive(css`
		width: 22px;
	`)}
`

const Accounting = styled(AccountingSVG)`
	${ProductIconStyles}
`

const ProductText = styled.span`
	${textStyles.subHeading3}
	color: ${colors.dark400};
`

const Cfo = styled(CFOSVG)`
	${ProductIconStyles}
`

const Tax = styled(TaxSVG)`
	${ProductIconStyles}
`

const Links = styled.div`
	display: flex;
	flex-direction: column;

	${fresponsive(css`
		gap: 10px;
	`)}
`

const Link = styled(UniversalLink)`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;

	${fresponsive(css`
		padding: 10px 10px 10px 0;
	`)}
`

const Arrow = styled(ArrowSVG)`
	height: auto;

	path {
		stroke: ${colors.mainBlack};
	}

	${fresponsive(css`
		width: 20px;
	`)}
`

const Buttons = styled.div`
	display: flex;
	align-items: center;

	${fresponsive(css`
		gap: 10px;
	`)}
`

const StyledButton = styled(Secondary)`
	div {
		text-align: center;
		width: calc(100%);
	}
`
