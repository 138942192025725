import gsap from "gsap"
import { CustomEase } from "gsap/CustomEase"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import ScrollTrigger from "gsap/ScrollTrigger"
import { ReactComponent as LogoSVG } from "images/global/logo.svg"
import { usePreloader } from "library/Loader/PreloaderUtils"
import { fresponsive } from "library/fullyResponsive"
import getMedia from "library/getMedia"
import useAnimation from "library/useAnimation"
import { getResponsivePixels } from "library/viewportUtils"
import { useRef } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"

gsap.registerPlugin(CustomEase as object)

const customEase =
	"M0,0 C0.302,0 0.307,0.708 0.578,0.904 0.686,0.982 0.752,1 1,1"

export default function Preloader() {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const logoBlackRef = useRef<HTMLDivElement>(null)
	const maskRef = useRef<HTMLDivElement>(null)
	const loadComplete = useRef(false)

	const onComplete = () => {
		loadComplete.current = true
	}

	useAnimation(() => {
		ScrollSmoother.get()?.paused(true)

		const tl = gsap.timeline({
			defaults: {
				ease: customEase,
				duration: 0.75,
			},
			onComplete: () => {
				gsap.set(wrapperRef.current, {
					display: "none",
					onComplete: () => {
						ScrollTrigger.refresh()
					},
				})
			},
		})

		tl.fromTo(
			logoBlackRef.current,
			{
				height: "0%",
			},
			{
				height: "100%",
				duration: 1.5,
			},
			0,
		)

		tl.to(
			maskRef.current,
			{
				width: () => getResponsivePixels(getMedia(55, 55, 98, 80)),
				height: () => getResponsivePixels(getMedia(16, 16, 30, 22)),
				y: () => getResponsivePixels(getMedia(33, 33, 46, 38)),
				x: () =>
					window.innerWidth / 2 -
					getResponsivePixels(getMedia(27.5, 27.5, 49, 40)),
				duration: 0.75,
			},
			2,
		)

		tl.to(
			maskRef.current,
			{
				left: 0,
				x: () => getResponsivePixels(getMedia(40, 40, 30, 17)),
				xPercent: 0,
				duration: 0.75,
			},
			2.75,
		)

		tl.to(
			wrapperRef.current,
			{
				autoAlpha: 0,
				duration: 0.75,
				onComplete: () => {
					ScrollSmoother.get()?.paused(false)
				},
			},
			3.5,
		)

		tl.to("main", {
			clearProps: "all",
		})
	}, [])

	usePreloader({
		callback: onComplete,
		duration: 1,
	})

	return (
		<Wrapper ref={wrapperRef}>
			<Mask ref={maskRef}>
				<LogoWrapper>
					<Logo />
				</LogoWrapper>
				<LogoBlackWrapper ref={logoBlackRef}>
					<Logo />
				</LogoBlackWrapper>
			</Mask>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	background-color: ${colors.mainWhite};
	z-index: 100;
	pointer-events: none;
	height: 100vh;
	width: 100vw;
`

const Mask = styled.div`
	overflow: hidden;
	position: absolute;
	transform: translate(calc(50vw - 50%), calc(50vh - 50%));

	${fresponsive(css`
		width: 220px;
		height: 66px;
	`)}
`

const LogoWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	svg {
		path {
			fill: ${colors.light400};
		}
	}
`

const Logo = styled(LogoSVG)`
	height: auto;
	width: 100%;
`

const LogoBlackWrapper = styled(LogoWrapper)`
	overflow: hidden;
	height: 0%;
	bottom: 0;
	top: unset;

	svg {
		position: absolute;
		bottom: 0;

		path {
			fill: ${colors.mainBlack};
		}
	}
`
