import gsap from "gsap"
import { usePageTransition } from "library/Loader/TransitionUtils"
import { useRef } from "react"
import styled from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

const Eases = {
	in: "power1.in",
	out: "power1.out",
}

export default function Transition() {
	const wrapperRef = useRef<HTMLDivElement>(null)

	const fadeIn = () => {
		gsap.to(wrapperRef.current, {
			duration: 1,
			opacity: 1,
			ease: Eases.in,
		})
		return 1
	}

	const fadeOut = () => {
		gsap.to(wrapperRef.current, {
			duration: 1,
			opacity: 0,
			delay: 0.2,
			ease: Eases.out,
		})
	}

	usePageTransition("fade", {
		in: fadeIn,
		out: fadeOut,
		inDuration: 1,
		outDuration: 1.2,
	})

	return <Wrapper ref={wrapperRef} />
}

const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: ${colors.mainWhite};
	z-index: 100;
	opacity: 0;
	pointer-events: none;
	display: grid;
	place-items: center;
	${textStyles.h1}
`
