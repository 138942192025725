import Footer from "components/Footer"
import Header from "components/Header"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useBackButton } from "library/Loader/TransitionUtils"
import Scroll from "library/Scroll"
import { useTrackPageReady } from "library/pageReady"
import styled, { createGlobalStyle, css } from "styled-components"
import { Banner } from "./Banner"
import Preloader from "./Preloader"
import Transition from "./Transition"

gsap.registerPlugin(ScrollTrigger)

interface LayoutProps {
	children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
	useTrackPageReady()
	useBackButton()

	return (
		<>
			<Transition />
			<Preloader />
			<GlobalStyle />
			<Header />
			<Scroll>
				{/* for spacing only */}
				<div style={{ visibility: "hidden" }}>
					<Banner />
				</div>
				<Main className="main">{children}</Main>
				<Footer />
			</Scroll>
		</>
	)
}

const Main = styled.main`
	overflow: hidden;
`

const globalCss = css`
	* {
		/* need this so that fonts match to figma */
		font-family: Aeonik, sans-serif;

		/* text-rendering: geometricprecision; */
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizelegibility;
		-webkit-text-rendering: optimizelegibility;
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-webkit-font-feature-settings:
			"clig" off,
			"liga" off;
		font-feature-settings:
			"clig" off,
			"liga" off;
	}

	/** restore default focus states for elements that need them */
	*:focus-visible {
		outline: 2px solid #f008;
	}
`

const GlobalStyle = createGlobalStyle`${globalCss}`
