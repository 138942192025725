import { Banner } from "components/Banner"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import useAnimation from "library/useAnimation"
import useMedia from "library/useMedia"
import { useRef, useState } from "react"
import styled from "styled-components"

import Desktop from "./Desktop"
import TabletMobile from "./TabletMobile"

export default function Header() {
	const desktop = useMedia(true, true, false, false)
	const wrapper = useRef<HTMLDivElement>(null)

	const [translateY, setTranslateY] = useState(0)

	useAnimation(() => {
		let lastScroll = 0

		/**
		 * when we scroll up, move the header down by delta pixels
		 * when we scroll down, move the header up by delta pixels
		 */
		ScrollTrigger.create({
			onUpdate: () => {
				const scroll = ScrollSmoother.get()?.scrollTop() ?? 0
				const delta = scroll - lastScroll
				lastScroll = scroll
				const height = wrapper.current?.offsetHeight ?? 0

				if (delta > 0) {
					setTranslateY((prev) => Math.max(-height, prev - delta))
				} else {
					setTranslateY((prev) => Math.min(0, prev - delta))
				}
			},
		})
	}, [])

	return (
		<Fixed
			ref={wrapper}
			style={{
				translate: `0 ${translateY}px`,
			}}
		>
			<Banner />
			{desktop && <Desktop />}
			{!desktop && <TabletMobile />}
		</Fixed>
	)
}

const Fixed = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9;
	transition: translate 0.1s ease-out;

	&:hover {
		translate: 0 0 !important;
	}
`
