import { gsap } from "gsap"
import type { Dispatch, SetStateAction } from "react"

export const randomNumber = (min: number, max: number) => {
	return Math.ceil(gsap.utils.random(min, max))
}

let initialCurrentAccounts = 10_321.78
let initialMoneyEarned = 54_166.81

export const animateGradient = (args: {
	gradient: Element
	start: number
	duration: number
	inOut: number
	size: number
	rightToLeft?: boolean
	tl: GSAPTimeline
}) => {
	const { gradient, start, duration, inOut, size, rightToLeft, tl } = args

	const x1Start = rightToLeft ? `${100 + size}%` : `0%`
	const x2Start = rightToLeft ? `100%` : `${-size}%`

	const x1Step2 = rightToLeft ? `100%` : `${size}%`
	const x2Step2 = rightToLeft ? `${100 - size}%` : `0%`

	const x1Step3 = rightToLeft ? `${size}%` : `${100 - size / 5}%`
	const x2Step3 = rightToLeft ? `${size / 10}%` : `${100 - size}%`

	const x1End = rightToLeft ? `0%` : `100%`
	const x2End = rightToLeft ? `0%` : `100%`

	tl.fromTo(
		gradient,
		{
			attr: {
				x1: x1Start,
				x2: x2Start,
			},
		},
		{
			attr: {
				x1: x1Step2,
				x2: x2Step2,
			},
			duration: inOut,
		},
		start,
	)
		.to(
			gradient,
			{
				duration,
				attr: { x1: x1Step3, x2: x2Step3 },
			},
			start + inOut,
		)
		.to(
			gradient,
			{
				duration: inOut,
				attr: { x1: x1End, x2: x2End },
			},
			start + inOut + duration,
		)
}

export const animateGradientY = (args: {
	gradient: Element
	start: number
	duration: number
	inOut: number
	size: number
	rightToLeft?: boolean
	tl: GSAPTimeline
}) => {
	const { gradient, start, duration, inOut, size, rightToLeft, tl } = args

	const x1Start = rightToLeft ? `${100 + size}%` : `0%`
	const x2Start = rightToLeft ? `100%` : `${-size}%`

	const x1Step2 = rightToLeft ? `100%` : `${size}%`
	const x2Step2 = rightToLeft ? `${100 - size}%` : `0%`

	const x1Step3 = rightToLeft ? `${size}%` : `${100 - size / 5}%`
	const x2Step3 = rightToLeft ? `${size / 10}%` : `${100 - size}%`

	const x1End = rightToLeft ? `0%` : `100%`
	const x2End = rightToLeft ? `0%` : `100%`

	tl.fromTo(
		gradient,
		{
			attr: {
				y1: x1Start,
				y2: x2Start,
			},
		},
		{
			attr: {
				y1: x1Step2,
				y2: x2Step2,
			},
			duration: inOut,
		},
		start,
	)
		.to(
			gradient,
			{
				duration,
				attr: { y1: x1Step3, y2: x2Step3 },
			},
			start + inOut,
		)
		.to(
			gradient,
			{
				duration: inOut,
				attr: { y1: x1End, y2: x2End },
			},
			start + inOut + duration,
		)
}

export const animateCoin = (args: {
	motionPathEl: Element
	target: string
	start: number
	duration: number
	inOut: number
	setState: Dispatch<SetStateAction<number>>
	tl: GSAPTimeline
}) => {
	const { motionPathEl, target, start, duration, inOut, setState, tl } = args

	tl.to(
		motionPathEl,
		{
			motionPath: {
				path: target,
				align: target,
				alignOrigin: [0.5, 0.5],
				autoRotate: true,
				offsetX: 0,
			},
			duration: inOut + duration,
			ease: "none",
		},
		start,
	)
		.fromTo(
			motionPathEl,
			{ autoAlpha: 0 },
			{ autoAlpha: 1, duration: inOut },
			start,
		)
		.to(
			motionPathEl,
			{
				autoAlpha: 0,
				duration: inOut,
				onComplete: () => {
					if (target === ".earn-lines-1" || target === ".earn-lines-3") {
						const currentAccounts =
							initialCurrentAccounts + randomNumber(1, 15) / 10
						setState(currentAccounts)
						initialCurrentAccounts = currentAccounts
					} else {
						const moneyEarned = initialMoneyEarned + randomNumber(1, 15) / 10
						setState(moneyEarned)
						initialMoneyEarned = moneyEarned
					}
				},
			},
			start + inOut + duration,
		)
}

export const drawPath = (
	target: Element,
	size: number,
	start: number,
	duration: number,
	inOut: number,
	tl: GSAPTimeline,
) => {
	tl.fromTo(
		target,
		{
			drawSVG: `0% 0%`,
		},
		{
			drawSVG: `0% ${size}%`,
			duration: inOut,
		},
		start,
	)
		.to(
			target,
			{
				duration,
				drawSVG: `100% ${100 - size}%`,
			},
			start + inOut,
		)
		.to(
			target,
			{
				duration: inOut,
				drawSVG: `100% 100%`,
			},

			start + inOut + duration,
		)
}

// Todo - make this work
// const trackGradient = (
//   gradient: Element,
//   tl: GSAPTimeline,
//   targetEl: SVGPathElement,
// ) => {
//   const updateGradient = () => {
//     const { x, y } = targetEl.getPointAtLength(
//       tl.progress() * targetEl.getTotalLength(),
//     )

//     const angle = (Math.atan2(y, x) * 180) / Math.PI
//     const x1 = x - 10
//     const x2 = x + 10
//     const y1 = y - 10
//     const y2 = y + 10

//     gsap.to(gradient, {
//       attr: {
//         x1,
//         x2,
//         y1,
//         y2,
//         gradientTransform: `rotate(${angle})`,
//       },
//     })
//   }

//   tl.eventCallback("onUpdate", updateGradient)
// }
