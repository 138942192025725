const colors = {
	mainWhite: "#FFFFFF",
	mainBlack: "#1D2632",
	mainBlue: "#001AFF",
	mainDarkBlack: "#0B0D11",

	// Blues
	blue100: "#939CFD",
	blue200: "#4659FF",
	blue300: "#3549FF",
	blue400: "#001AFF",
	blue500: "#0319E0",
	blue600: "#091BC3",

	// secondary

	pink400: "#FF007A",
	purple400: "#BF11C9",

	// Darks

	dark200: "#718096",
	dark300: "#4F5F76",
	dark400: "#374458",
	dark500: "#293342",
	dark600: "#1D2632",
	dark700: "#121821",
	dark800: "#0B0D11",

	// Lights
	light100: "#FFFFFF",
	light200: "#FCFDFD",
	light300: "#F7F9FA",
	light400: "#EDF0F1",
	light500: "#E2E7E9",
	light600: "#D7DCDE",
	light700: "#CBCFD1",
} as const

export default colors
