import { fresponsive } from "library/fullyResponsive"
import { css } from "styled-components"
import "./typography.css"

const featureSettings = `
font-family: Aeonik, sans-serif;
`

const textStyles = {
	h1: fresponsive(css`
		${featureSettings}
		font-size: 188px;
		font-style: normal;
		font-weight: 400;
		line-height: 96%;
		letter-spacing: -7.52px;
	`),
	h2: fresponsive(css`
		${featureSettings}
		font-size: 150px;
		font-style: normal;
		font-weight: 400;
		line-height: 96%; /* 144px */
		letter-spacing: -6px;
	`),
	h3: fresponsive(css`
		${featureSettings}
		font-size: 110px;
		font-style: normal;
		font-weight: 400;
		line-height: 96%; /* 105.6px */
		letter-spacing: -4.4px;
	`),
	h4: fresponsive(css`
		${featureSettings}

		font-size: 86px;
		font-style: normal;
		font-weight: 400;
		line-height: 96%;
		letter-spacing: -3.44px;
	`),
	h5: fresponsive(css`
		${featureSettings}

		font-size: 68px;
		font-style: normal;
		font-weight: 400;
		line-height: 96%; /* 65.28px */
		letter-spacing: -2.04px;
	`),
	h6: fresponsive(css`
		${featureSettings}

		font-size: 52px;
		font-style: normal;
		font-weight: 400;
		line-height: 96%; /* 49.92px */
		letter-spacing: -1.56px;
	`),
	h7: fresponsive(css`
		${featureSettings}
		font-size: 40px;
		font-style: normal;
		font-weight: 400;
		line-height: 96%; /* 38.4px */
		letter-spacing: -1.2px;
	`),
	h8: fresponsive(css`
		${featureSettings}
		font-size: 34px;
		font-style: normal;
		font-weight: 400;
		line-height: 96%;
		letter-spacing: -1.02px;
	`),

	// SubHeading Styles

	subHeading1: fresponsive(css`
		${featureSettings}
		font-size: 22px;
		font-style: normal;
		font-weight: 400;
		line-height: 114%;
		letter-spacing: -0.22px;
	`),
	subHeading1Bold: fresponsive(css`
		${featureSettings}
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: 114%;
		letter-spacing: 0;
	`),
	subHeading2: fresponsive(css`
		${featureSettings}
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 114%;
		letter-spacing: -0.2px;
	`),
	subHeading2Bold: fresponsive(css`
		${featureSettings}
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 114%;
		letter-spacing: 0;
	`),

	subHeading3: fresponsive(css`
		${featureSettings}
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 114%; /* 18.24px */
		letter-spacing: -0.16px;
	`),
	subHeading3Bold: fresponsive(css`
		${featureSettings}
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 114%; /* 18.24px */
		letter-spacing: 0;
	`),

	subHeading4: fresponsive(css`
		${featureSettings}
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 114%;
		letter-spacing: -0.14px;
	`),
	subHeading4Bold: fresponsive(css`
		${featureSettings}
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 114%;
		letter-spacing: 0;
	`),

	// Kicker Styles

	kicker1: fresponsive(css`
		${featureSettings}
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.36px;
		text-transform: uppercase;
	`),
	kicker2: fresponsive(css`
		${featureSettings}
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.32px;
		text-transform: uppercase;
	`),
	kicker3: fresponsive(css`
		${featureSettings}
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.28px;
		text-transform: uppercase;
	`),

	// Button Styles

	button1Medium: fresponsive(css`
		${featureSettings}
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;
		letter-spacing: -0.18px;
	`),
	button1Bold: fresponsive(css`
		${featureSettings}
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 106%;
		letter-spacing: 0;
	`),
	button2Bold: fresponsive(css`
		${featureSettings}
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 106%; /* 16px */
		letter-spacing: 0;
	`),
	button2Medium: fresponsive(css`
		${featureSettings}
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;
		letter-spacing: -0.16px;
	`),
	button3Medium: fresponsive(css`
		${featureSettings}
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;
		letter-spacing: -0.14px;
	`),
	// Body Styles
	body1_THIS_IS_NOT_CORRECT: fresponsive(css`
		${featureSettings}
		font-size: 24px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 28.8px */
	`),
	body1: fresponsive(css`
		${featureSettings}
		font-size: 24px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 28.8px */
	`),
	body1Light: fresponsive(css`
		${featureSettings}
		font-size: 24px;
		font-style: normal;
		font-weight: 300;
		line-height: 120%;
	`),
	body2: fresponsive(css`
		${featureSettings}
		font-size: 22px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		letter-spacing: -0.14px;
	`),
	body2Light: fresponsive(css`
		${featureSettings}
		font-size: 22px;
		font-style: normal;
		font-weight: 300;
		line-height: 120%;
	`),
	body3: fresponsive(css`
		${featureSettings}
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
	`),
	body3Light: fresponsive(css`
		${featureSettings}
		font-size: 18px;
		font-style: normal;
		font-weight: 300;
		line-height: 120%;
	`),
	body4: fresponsive(css`
		${featureSettings}
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 130%;
	`),
	body4Light: fresponsive(css`
		${featureSettings}
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 130%;
	`),
	body5: fresponsive(css`
		${featureSettings}
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 130%;
	`),
	body5Light: fresponsive(css`
		${featureSettings}
		font-size: 14px;
		font-style: normal;
		font-weight: 300;
		line-height: 130%;
	`),
}

export const strokeText = css`
	/* styled doesn't prefix this property yet */
	/* stylelint-disable property-no-vendor-prefix */

	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	background-size: 100%;
	background-clip: text;
	-webkit-text-stroke-width: 0.07vw;
`

export default textStyles
