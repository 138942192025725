import gsap from "gsap"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
import useAnimation from "library/useAnimation"
import type React from "react"
import { useRef } from "react"
import styled from "styled-components"
import { animateGradient, drawPath } from "utils/lineAnimationHelpers"

/**
 * You'll want to add the same root className to the gradient  element in the SVG. so "my-class" on the path element will be "my-class-grad" on the gradient element.   See footerLines.svg for an example
 *
 */
gsap.registerPlugin(DrawSVGPlugin, MotionPathPlugin)

const START_VAL = "top bottom"
const END_VAL = "bottom top"
const TOGGLE_ACTIONS = "play pause resume pause"

interface Props {
	children: React.ReactNode
	continuous?: boolean
	className?: string
	sizeRange?: [number, number]
	durationRange?: [number, number]
	animationClassName: string
	rightToLeft?: boolean[]
}
const randomNumber = (min: number, max: number) => {
	return Math.ceil(gsap.utils.random(min, max))
}

const randomSort = () => {
	return Math.random() - 0.5
}

export default function AnimatedPath({
	children,
	className = "",
	animationClassName = "",
	sizeRange = [8, 14],
	durationRange = [1, 2.4],
	rightToLeft = [],
}: Props) {
	const wrapper = useRef<HTMLDivElement>(null)

	useAnimation(() => {
		if (wrapper.current && children) {
			const allPaths = [
				...wrapper.current.querySelectorAll(`.${animationClassName}`),
			] as SVGPathElement[]

			const allGradients = [
				...wrapper.current.querySelectorAll(`.${animationClassName}-grad`),
			]

			const pathsAndGradients = allPaths
				.map((path, i) => {
					const gradientEl = allGradients[i]
					return { path, gradientEl }
				})
				.sort((_a, _b) => randomSort())

			const tl = gsap.timeline({
				defaults: {
					ease: "none",
				},
				repeat: -1,
				repeatRefresh: true,

				scrollTrigger: {
					trigger: wrapper.current,
					start: START_VAL,
					end: END_VAL,
					toggleActions: TOGGLE_ACTIONS,
				},
			})

			pathsAndGradients.forEach((path, i) => {
				const { path: pathEl } = path

				const gradientEl = path.gradientEl
				const startOffset = i * 0.5
				const size = randomNumber(sizeRange[0], sizeRange[1])
				const duration = randomNumber(durationRange[0], durationRange[1])
				const inOut = duration * (size / 100)
				const rTL = rightToLeft[i]

				drawPath(pathEl, size, startOffset, duration, inOut, tl)

				if (gradientEl && wrapper.current) {
					const args = {
						gradient: gradientEl,
						targetEl: pathEl,
						start: startOffset,
						duration,
						inOut,
						size,
						rightToLeft: rTL ?? false,
						tl,
					}

					animateGradient(args)
				}
			})
		}
	}, [children, animationClassName, sizeRange, durationRange, rightToLeft])

	return (
		<SVGWrapper className={className} ref={wrapper}>
			{children}
		</SVGWrapper>
	)
}

const SVGWrapper = styled.div``
