import gsap from "gsap"
import type {
	UniversalLinkProps,
	UniversalLinkRef,
} from "library/Loader/UniversalLink"
import UniversalLink from "library/Loader/UniversalLink"
import { fresponsive } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useRef } from "react"
import styled, { css } from "styled-components"
import { generateGradientBorder } from "utils/generateGradientBorder"

const GRADIENT_1 = `
  linear-gradient(
    -252deg,
    rgb(0, 26, 255, 0) 0%,
    #001aff 10%,
    #001aff 90%,
    rgb(0, 26, 255, 0) 100%
  )
`

const GRADIENT_2 = `
  linear-gradient(
    35deg,
    rgb(0, 26, 255, 0) 0%,
    #001aff 60%,
    #001aff 75%,
    rgb(0, 26, 255, 0) 100%
  )
`

const GRADIENT_3 = `
  linear-gradient(
    -25deg,
    rgb(0, 26, 255, 0) 0%,
    #001aff 40%,
    #001aff 65%,
    rgb(0, 26, 255, 0) 100%
  )
`

export type BaseButtonProps = UniversalLinkProps & {
	border?: boolean
}

export default function BaseButton({
	children,
	border,
	className,
	...props
}: BaseButtonProps) {
	const wrapperRef = useRef<UniversalLinkRef>(null)
	const activeTl = useRef<GSAPTimeline | null>(null)

	useAnimation(() => {
		const tl = gsap.timeline({
			repeat: -1,
			yoyo: true,
			paused: !border,
			defaults: {
				ease: "none",
			},
		})

		tl.fromTo(
			wrapperRef.current,
			{
				"--background": GRADIENT_1,
			},
			{
				"--background": GRADIENT_2,
				duration: 4,
			},
		)

		tl.to(wrapperRef.current, {
			"--background": GRADIENT_3,
			duration: 5,
		})

		activeTl.current = tl
	}, [border])

	const mouseEnter = () => {
		if (activeTl.current && !border) {
			activeTl.current.play()
		}
	}

	const mouseLeave = () => {
		if (!border) {
			if (activeTl.current) {
				activeTl.current.pause()
			}

			gsap.set(wrapperRef.current, {
				"--background": GRADIENT_1,
			})
		}
	}

	return (
		<Wrapper
			{...props}
			$border={Boolean(border)}
			className={className}
			forwardRef={wrapperRef}
			onMouseEnter={mouseEnter}
			onMouseLeave={mouseLeave}
		>
			{children}
		</Wrapper>
	)
}

const Wrapper = styled(UniversalLink)<{
	$border: boolean
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 100%;
	height: 100%;
	position: relative;
	--background: ${GRADIENT_1};

	${({ $border }) =>
		$border
			? fresponsive(css`
					&::before {
						position: absolute;
						content: "";
						inset: 0;
						border-radius: inherit;
						${generateGradientBorder("var(--background)", 2)}
						background-size: 150% 150%;
					}
				`)
			: css`
					background-position: center center;
					background-image: var(--background);
					background-size: 150% 150%;
				`}
`
