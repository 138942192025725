import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

import type { BaseButtonProps } from "./Base"
import BaseButton from "./Base"

export default function SecondaryButton({
	children,
	border,
	...props
}: BaseButtonProps) {
	return (
		<Wrapper border={border} {...props}>
			<Fill $border={!!border}>
				<Mask>
					<Text>{children}</Text>
					<Text>{children}</Text>
				</Mask>
			</Fill>
		</Wrapper>
	)
}

const Text = styled.div`
	position: relative;
	width: fit-content;
	${textStyles.button3Medium};
	transition: transform 0.5s;
	white-space: nowrap;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	vertical-align: text-top;

	${fresponsive(css`
		height: 20px;
	`)}

	${ftablet(css`
		${textStyles.button1Bold};
	`)};

	${fmobile(css`
		${textStyles.button1Bold};
	`)};
`

const Wrapper = styled(BaseButton)`
	${fresponsive(css`
		&:hover {
			${Text} {
				transform: translateY(calc(-100% - 5px));
			}
		}

		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 2px;
		height: 44px;
		border-radius: 10px;
	`)};

	${ftablet(css`
		height: 50px;
	`)};
`

const Fill = styled.div<{ $border: boolean }>`
	display: flex;
	align-items: center;
	background-color: transparent;
	color: ${colors.mainDarkBlack};
	width: fit-content;

	${Text} {
		color: ${(props) => (props.$border ? colors.mainBlack : colors.light300)};
	}

	${fresponsive(css`
		height: calc(100% - 4px);
		padding: 0 15px;
		border-radius: 8px;
		gap: 78px;
	`)}

	${ftablet(css`
		padding: 0;
		gap: 0;
	`)};
`

const Mask = styled.div`
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	${fresponsive(css`
		height: 20px;
		gap: 5px;
	`)}
`
