import SecondaryButton from "components/Buttons/Secondary"
import gsap from "gsap"
import { ReactComponent as LogoSVG } from "images/global/logo.svg"
import { ReactComponent as AccountingSVG } from "images/icons/accounting.svg"
import { ReactComponent as CfoSVG } from "images/icons/cfo.svg"
import { ReactComponent as TaxSVG } from "images/icons/tax.svg"
import UniversalLink from "library/Loader/UniversalLink"
import { fresponsive } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"

import DropdownBackground from "./DropdownBackground"

export default function Header() {
	const dropdownRef = useRef<HTMLDivElement>(null)
	const line1Ref = useRef<SVGLineElement | null>(null)
	const line2Ref = useRef<SVGLineElement | null>(null)
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const DROPDOWN_LINKS = [
		{
			name: "Accounting",
			to: links.accounting,
			icon: (
				<IconWrap>
					<AccountingSVG />
				</IconWrap>
			),
		},
		{
			name: "CFO",
			to: links.cfo,
			icon: (
				<IconWrap>
					<CfoSVG />
				</IconWrap>
			),
		},
		{
			name: "Tax",
			to: links.tax,
			icon: (
				<IconWrap>
					<TaxSVG />
				</IconWrap>
			),
		},
	]

	const dropdownTl = useAnimation(
		() => {
			const tl = gsap.timeline({
				paused: true,
			})

			tl.to(
				dropdownRef.current,
				{
					height: "auto",
				},
				0,
			)

			tl.to(
				line1Ref.current,
				{
					attr: {
						y1: 6,
						y2: 2,
					},
				},
				0,
			)

			tl.to(
				line2Ref.current,
				{
					attr: {
						y1: 2,
						y2: 6,
					},
				},
				0,
			)

			return tl
		},
		[],
		{
			recreateOnResize: true,
		},
	)

	useEffect(() => {
		if (dropdownTl) {
			if (dropdownOpen) {
				dropdownTl.play()
			} else {
				dropdownTl.reverse()
			}
		}
	}, [dropdownOpen, dropdownTl])

	const dropdownLinks = DROPDOWN_LINKS.map((link) => (
		<DropdownLink key={link.name} to={link.to}>
			{link.icon} {link.name}
		</DropdownLink>
	))

	return (
		<Wrapper>
			<UniversalLink to={links.home} aria-label="home">
				<Logo />
			</UniversalLink>
			<Links>
				<Link
					type="button"
					onMouseEnter={() => setDropdownOpen(true)}
					onMouseLeave={() => setDropdownOpen(false)}
				>
					<LinkMaskLink to="/">
						<span>Products</span>
						<span>Products</span>
					</LinkMaskLink>
					<DropdownChevron viewBox="0 0 10 8">
						<Line ref={line1Ref} x1={1} x2={5} y1={2} y2={6} />
						<Line ref={line2Ref} x1={5} x2={9} y1={6} y2={2} />
					</DropdownChevron>
					<Dropdown ref={dropdownRef}>
						<DropdownBackground />
						<DropdownContent>{dropdownLinks}</DropdownContent>
					</Dropdown>
				</Link>
				<Link to={links.expert}>
					<LinkMask>
						<span>Become an Expert</span>
						<span>Become an Expert</span>
					</LinkMask>
				</Link>
				<Link to={links.company}>
					<LinkMask>
						<span>Company</span>
						<span>Company</span>
					</LinkMask>
				</Link>
				<Buttons>
					<SecondaryButton to={links.signIn}>Sign In</SecondaryButton>
					<SecondaryButton to={links.demo} border>
						Book Demo
					</SecondaryButton>
				</Buttons>
			</Links>
		</Wrapper>
	)
}

const IconWrap = styled.div`
	${fresponsive(css`
		width: 18px;
		height: 18px;

		svg {
			width: 100%;
			height: 100%;
		}
	`)}
`

const DropdownLink = styled(UniversalLink)`
	width: 100%;
	${textStyles.subHeading4}
	color: ${colors.dark400};
	display: flex;
	align-items: center;
	cursor: pointer;
	transition-property: background-color, color;
	transition-duration: 0.3s;
	transition-timing-function: ease-in-out;

	svg {
		path {
			fill: ${colors.dark400};
			transition-duration: 0.3s;
			transition-timing-function: ease-in-out;
		}
	}

	&:hover {
		color: ${colors.mainBlue};
		background-color: ${colors.light300};

		svg {
			path {
				fill: ${colors.mainBlue};
			}
		}
	}

	${fresponsive(css`
		height: 34px;
		border-radius: 5px;
		gap: 10px;
		padding: 10px;
	`)}
`

const Wrapper = styled.header`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 9;
	top: 0;
	left: 0;
	position: relative;

	&::before {
		position: absolute;
		inset: 0;
		z-index: -1;
		content: "";
		background: linear-gradient(
				to bottom,
				rgb(255 255 255 / 80%) -4.03%,
				rgb(255 255 255 / 0%) 95.3%
			),
			rgb(255 255 255 / 55%);
		backdrop-filter: blur(3.5px);
	}

	${fresponsive(css`
		height: 75px;
		padding: 20px 40px;
	`)}
`

const Logo = styled(LogoSVG)`
	height: auto;

	${fresponsive(css`
		width: 55px;
	`)}
`

const Links = styled.div`
	display: flex;
	align-items: center;

	${fresponsive(css`
		gap: 40px;
	`)}
`

const Link = styled(UniversalLink)`
	position: relative;
	display: flex;
	align-items: center;
	${textStyles.button3Medium};

	span {
		transition: transform 0.3s ease-in-out;

		&:first-of-type {
			color: ${colors.mainBlack};
		}

		&:last-of-type {
			color: ${colors.mainBlue};
		}
	}

	${fresponsive(css`
		gap: 5px;
		height: 15px;

		&:hover {
			span {
				transform: translateY(calc(-100% - 6px));
			}
		}
	`)}
`

const LinkMaskLink = styled(UniversalLink)`
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;

	${fresponsive(css`
		gap: 6px;
	`)}
`

const DropdownChevron = styled.svg`
	height: auto;

	${fresponsive(css`
		width: 8px;
	`)}
`

const Line = styled.line`
	stroke: ${colors.dark400};
	stroke-width: 1px;
`

const Dropdown = styled.div`
	position: absolute;
	height: 0;
	overflow: hidden;

	${fresponsive(css`
		width: 180px;
		top: 160%;
		left: -25px;
		box-shadow: rgb(0 0 0 / 4%) 0 18px 20px;
		border-radius: 10px;
	`)}
`

const DropdownContent = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	z-index: 2;
	overflow: hidden;
	position: relative;
	background-color: #ffffff90;

	${fresponsive(css`
		margin-top: 5.277px;
		gap: 5px;
		padding: 15px 10px;
		border-radius: 10px;
		backdrop-filter: blur(5px);
	`)}
`

const LinkMask = styled.div`
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;

	${fresponsive(css`
		gap: 5px;
	`)}
`

const Buttons = styled.div`
	display: flex;
	align-items: center;

	${fresponsive(css`
		gap: 20px;
	`)}
`
