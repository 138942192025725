import StyledManager from "library/StyledManager"

import { ScreenProvider } from "library/ScreenContext"

interface ProvidersProps {
	children: React.ReactNode
}

export default function Providers({ children }: ProvidersProps) {
	return (
		<StyledManager>
			<ScreenProvider>{children}</ScreenProvider>
		</StyledManager>
	)
}
