exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-book-demo-index-tsx": () => import("./../../../src/pages/book-demo/index.tsx" /* webpackChunkName: "component---src-pages-book-demo-index-tsx" */),
  "component---src-pages-book-demo-thanks-tsx": () => import("./../../../src/pages/book-demo/thanks.tsx" /* webpackChunkName: "component---src-pages-book-demo-thanks-tsx" */),
  "component---src-pages-experts-tsx": () => import("./../../../src/pages/experts.tsx" /* webpackChunkName: "component---src-pages-experts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

